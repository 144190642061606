<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col-lg-12">
          <div class="d-flex align-items-center">
            <img src="stoke/assets/images/users/avatar-1.jpg" alt="" class="avatar-sm rounded" />
            <div class="ms-3 flex-grow-1">
              <h5 class="mb-2 card-title">{{ user.name }}</h5>
              <p class="text-muted mb-0">Thống kê duyệt tiền</p>
            </div>
            <div>
              <a href="javascript:void(0);" class="btn btn-primary"
                ><i class="bx bx-plus align-middle"></i> Add New Jobs</a
              >
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row">
        <div class="col-lg-12">
          <div class="row mb-4">
            <div class="col-12 fw-bold">
              <h5 class="text-primary">Chọn thời gian thống kê</h5>
            </div>
            <div class="col-md-12">
              <date-range-picker
                ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                v-model="dateRange"
                :autoApply="true"
                opens="right"
                :show-week-numbers="false"
                class="issue__filter__time_picker w-100"
                custom-range-label="Chọn ngày bắt đầu - kết thúc"
                :singleDatePicker="false"
                :show-ranges="true"
                @update="getStatistics"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3">
          <div class="card mini-stats-wid">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <p class="text-muted fw-medium">Tổng tiền đã rút</p>
                  <h4 class="mb-0">{{ total }}</h4>
                </div>

                <div class="flex-shrink-0 align-self-center">
                  <div
                    data-colors='["--bs-success", "--bs-transparent"]'
                    dir="ltr"
                    id="eathereum_sparkline_charts"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-4">Thống kê theo ngày</h4>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap mb-0">
                  <thead class="table-light">
                    <tr>
                      <th class="align-middle">Ngày</th>
                      <th class="align-middle">Số tiền</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(statistic, index) in statisticsByDate" :key="index">
                      <td>
                        <a href="javascript: void(0);" class="text-body fw-bold">{{
                          statistic.date.replace(" 00:00:00", "")
                        }}</a>
                      </td>
                      <td>{{ statistic.total | formatNumber }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
  </div>
  <!-- container-fluid -->
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import _ from "lodash";
import withdrawApi from "@/api/withdraw";
import moment from "moment";

export default {
  name: "Home",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      total: 0,
      statistics: {},
      statisticsByDate: [],
      dateRange: {
        startDate: moment().startOf("month"),
        endDate: new Date(),
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isManager() {
      return this.user.role && this.user.role.length > 1 && this.user.role !== "user";
    },
  },
  created() {
    this.getStatistics();
  },
  methods: {
    async getStatistics() {
      this.fetchStatisticsByDate();
    },
    async fetchStatisticsByDate() {
      const { startDate, endDate } = this.dateRange;
      console.log({ startDate, endDate });
      const response = await withdrawApi.statisticsByDate(
        `?date_from=${moment(startDate).format("YYYY-MM-DD")}&date_to=${moment(endDate).format(
          "YYYY-MM-DD"
        )}`
      );
      if (response.data) {
        this.statisticsByDate = response.data;
        console.log(this.statisticsByDate);
        this.total = response.total;
      }
    },
  },
};
</script>

<style scoped></style>
